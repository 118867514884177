import { cn } from "@nephroflow/design-system/styling/utils";
import {
  Column,
  ColumnDef,
  ColumnFilter,
  ColumnMeta,
  flexRender,
  getCoreRowModel,
  PaginationState,
  TableOptions,
  Table as TableT,
  useReactTable,
} from "@tanstack/react-table";
import { useLayoutEffect, useRef, useState } from "react";
import { z } from "zod";

import { defineMessages, FormattedMessage } from "~/intl";

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./table";

interface DataTableProps<TData> {
  table: TableT<TData>;
  isFetching?: boolean;
  withBorders?: boolean;
  hideHeader?: boolean;
  onRowClick?: (rowData: TData) => void;
  rowClassName?: string;
  className?: string;
}

function DataTable<TData extends object>({
  table,
  isFetching = false,
  withBorders,
  hideHeader = false,
  onRowClick,
  rowClassName: globalRowClassName,
  className,
}: DataTableProps<TData>) {
  const tableRef = useRef<HTMLTableElement>(null);

  const [showShadowRight, setShowShadowRight] = useState(false);
  const [showShadowLeft, setShowShadowLeft] = useState(false);

  const updateShadadow = () => {
    const tableEle = tableRef.current!;

    if (tableEle !== null) {
      const maxScroll = tableEle.scrollWidth - tableEle.clientWidth;
      setShowShadowRight(tableEle.scrollLeft < maxScroll);
      setShowShadowLeft(tableEle.scrollLeft > 0);
    }
  };

  useLayoutEffect(() => {
    updateShadadow();
  });

  return (
    <div
      className={cn(
        "flex",
        withBorders ? "rounded border" : "border-b border-t",
        "overflow-y-auto rounded-b border border-gray-10",
        {
          "relative after:absolute after:bottom-0 after:right-0 after:top-0 after:z-10 after:w-[1rem] after:bg-gradient-to-r after:from-transparent after:to-[#7878781A] after:content-['']":
            showShadowRight,
        },
        {
          "relative before:absolute before:bottom-0 before:left-[149px] before:top-0 before:w-[1rem] before:content-['']":
            showShadowLeft,
        },
        className,
      )}
    >
      <div
        className={cn("relative block w-full", {
          "overflow-x-auto": withBorders,
        })}
        ref={tableRef}
        onScroll={updateShadadow}
      >
        <Table className="w-full">
          <TableHeader className="sticky top-0 z-10" hidden={hideHeader}>
            {table.getHeaderGroups().map((headerGroup) => {
              // E.g. '1fr 3fr 1.25fr'
              const gridTemplateColumns = headerGroup.headers
                .map((header) => header.getSize())
                .map((size) => `minmax(150px, ${size}fr)`)
                .join(" ");

              return (
                <TableRow key={headerGroup.id} style={{ display: "grid", gridTemplateColumns }}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead
                        key={header.id}
                        className={cn("min-w-max", {
                          [getShadowRightGradient()]: header.column.columnDef.meta?.sticky && showShadowLeft,
                        })}
                      >
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHead>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableHeader>
          <TableBody className={cn({ "opacity-50": isFetching })}>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => {
                // E.g. '1fr 3fr 1.25fr'
                const gridTemplateColumns = row
                  .getVisibleCells()
                  .map((cell) => cell.column.getSize())
                  .map((size) => `minmax(150px, ${size}fr)`)
                  .join(" ");

                const rowClassName =
                  "rowClassName" in row.original && typeof row.original.rowClassName === "string"
                    ? row.original.rowClassName
                    : null;
                return (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    className={cn("border-gray-10", globalRowClassName, rowClassName, {
                      "hover:bg-gray-10": onRowClick,
                    })}
                    style={{
                      display: "grid",
                      gridTemplateColumns,
                    }}
                    onClick={() => onRowClick?.(row.original)}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        key={cell.id}
                        className={cn("min-w-0 border-gray-10", {
                          [getShadowRightGradient()]: cell.column?.columnDef.meta?.sticky && showShadowLeft,
                        })}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })
            ) : (
              <TableRow className="border-gray-10">
                <TableCell colSpan={table.getAllLeafColumns().length} className="h-12 font-normal text-gray-70">
                  <FormattedMessage {...t.noData} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

const dataTableProps = {
  manualPagination: true,
  manualFiltering: true,
  defaultColumn: { size: 4, minSize: 1, maxSize: 100 },
  getCoreRowModel: getCoreRowModel(),
} satisfies Partial<TableOptions<unknown>>;

interface UseDataTableOptions<TData, TValue = unknown> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  pagination?: PaginationState;
  filters?: ColumnFilter[];
}

function useDataTable<TData, TValue = unknown>({
  columns,
  data,
  pagination,
  filters,
}: UseDataTableOptions<TData, TValue>) {
  const table = useReactTable({
    ...dataTableProps,
    data,
    columns,
    state: { pagination, columnFilters: filters },
  });
  useHideHiddenDataTableColumns(table);

  return table;
}

function useHideHiddenDataTableColumns(table: TableT<any>) {
  useLayoutEffect(() => {
    const columns = table.getAllLeafColumns();
    const columnVisibility = Object.fromEntries(
      columns.map((column) => [column.id, column.columnDef.meta?.hidden !== true]),
    );
    table.setColumnVisibility(columnVisibility);
  }, [table]);
}

function createDataTablePagination(params: URLSearchParams) {
  const NumberParamSchema = z.string().pipe(z.coerce.number());
  const safeIndex = NumberParamSchema.safeParse(params.get("page"));
  const safeSize = NumberParamSchema.safeParse(params.get("pageSize"));
  const pagination: PaginationState = {
    pageIndex: safeIndex.success ? safeIndex.data : 0,
    pageSize: safeSize.success ? safeSize.data : 30,
  };
  return pagination;
}

function createDataTableFilters(params: URLSearchParams, columns: ColumnDef<any, unknown>[] | Column<any, unknown>[]) {
  return columns
    .map(getColumnDef)
    .filter(isColumnDefFilterable)
    .reduce<ColumnFilter[]>((acc, columnDef) => {
      const columnId = columnDef.id!;
      const filterDef = columnDef.meta!.filter!;
      const paramName = getParamName(columnId, filterDef);

      if (filterDef.type === "text" || (filterDef.type === "select" && !filterDef.multiple)) {
        const value = filterAsString(params.get(paramName));
        if (value !== undefined) acc.push({ id: columnId, value });
        return acc;
      }

      if (filterDef.type === "select" && filterDef.multiple) {
        const value = filterAsStringArray(params.getAll(paramName));
        if (value !== undefined) acc.push({ id: columnId, value });
        return acc;
      }

      if (filterDef.type === "dateRange") {
        const value = {
          from: params.get("start_time"),
          to: params.get("end_time"),
        };
        if (value !== undefined) acc.push({ id: columnId, value });
        return acc;
      }

      return acc;
    }, []);
}

function filterAsString(value: unknown) {
  const safe = z.string().min(1).safeParse(value);
  return safe.success ? safe.data : undefined;
}

function filterAsStringArray(value: unknown) {
  const safe = z.array(z.string().min(1)).nonempty().safeParse(value);
  return safe.success ? safe.data : undefined;
}

function filterAsDateRange(value: unknown) {
  const safe = z
    .object({
      from: z.date().or(z.string()),
      to: z.date().or(z.string()).nullable(),
    })
    .safeParse(value);

  if (safe.success) {
    return {
      start_time: safe.data.from,
      end_time: safe.data.to,
    };
  }

  return undefined;
}

function getColumnDef(column: ColumnDef<any, unknown> | Column<any, unknown>) {
  return "columnDef" in column ? column.columnDef : column;
}

function isColumnDefFilterable(columnDef: ColumnDef<any, unknown>) {
  return columnDef.id && columnDef.enableColumnFilter && columnDef.meta?.filter;
}

function applyDataTableFiltersToParams(
  params: URLSearchParams,
  filters: ColumnFilter[],
  columns: ColumnDef<any, unknown>[] | Column<any, unknown>[],
) {
  const newParams = new URLSearchParams(params);
  const columnsDefs = columns.map(getColumnDef).filter(isColumnDefFilterable);

  for (const columnDef of columnsDefs) {
    const columnId = columnDef.id!;
    const filterDef = columnDef.meta!.filter!;
    const paramName = getParamName(columnId, filterDef);
    newParams.delete(paramName);
  }

  for (const columnDef of columnsDefs) {
    const columnId = columnDef.id!;
    const filterDef = columnDef.meta!.filter!;
    const filter = filters.find((filter) => filter.id === columnId);
    const paramName = getParamName(columnId, filterDef);

    if (filterDef.type === "text" || (filterDef.type === "select" && !filterDef.multiple)) {
      const value = filterAsString(filter?.value);
      if (value !== undefined) {
        newParams.set(paramName, value);
      }
      continue;
    }

    if (filterDef.type === "select" && filterDef.multiple) {
      const value = filterAsStringArray(filter?.value);
      if (value !== undefined) {
        value.forEach((value) => newParams.append(paramName, value));
      }
      continue;
    }

    if (filterDef.type === "dateRange") {
      const value = filterAsDateRange(filter?.value);
      if (value != null) {
        // this checks for both undefined and null
        for (const [key, val] of Object.entries(value)) {
          let parsedVal = "";
          if (typeof val === "string" && !isNaN(Date.parse(val))) {
            parsedVal = new Date(val).toISOString();
          } else if (val instanceof Date) {
            parsedVal = val.toISOString();
          }

          newParams.set(key, parsedVal);
        }
      }

      continue;
    }
  }

  return newParams;
}

function getParamName(columnId: string, filterDef: NonNullable<ColumnMeta<unknown, unknown>["filter"]>) {
  return filterDef.type === "select" && filterDef.multiple ? `${columnId}[]` : columnId;
}

function getShadowRightGradient() {
  return cn([
    // default
    "sticky left-0 z-10 bg-blue-00",
    // after
    "after:pointer-events-none after:absolute after:inset-y-0 after:right-0 after:w-[10px] after:bg-gradient-to-r after:from-gray-50/[0.1] after:to-transparent after:content-['']",
  ]);
}

const t = defineMessages({
  noData: {
    id: "data_table_no_data",
    defaultMessage: "Nothing is here yet.",
  },
});

export {
  DataTable,
  applyDataTableFiltersToParams,
  createDataTableFilters,
  createDataTablePagination,
  dataTableProps,
  filterAsDateRange,
  filterAsString,
  filterAsStringArray,
  getColumnDef,
  isColumnDefFilterable,
  useDataTable,
  useHideHiddenDataTableColumns,
};

export type { DataTableProps, UseDataTableOptions };
